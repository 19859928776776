import React from 'react';
import TenantAPI from 'api/tenantAPI';
import { Button, Page, PageHero, Input, RadioGroup, FormGroup, TextArea, LoadingScreen } from '@punchcard/core';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PickListSelect } from 'components/core/PicklistSelect';
import { IconArrowLeft } from '@punchcard/core/icons';
import ResourceNaming from './ResourceNaming';

const TenantDetailsPage = () => {
	const { networkId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const tenantIdNumber = Number(networkId);


	const pageTitle = parseInt(networkId ?? '0') === 0 ? t('networks.add_network') : t('networks.edit_network');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);

	const methods = useForm<Tenant>({
		defaultValues: {
			name: '',
			isActive: true,
		}
	});

	React.useEffect(() => {
		async function getTenant() {
			setIsLoadingPage(true);
			const tenantData = await TenantAPI.getTenant(networkId);
			methods.reset(tenantData);
			setIsLoadingPage(false);
		}
		if (tenantIdNumber && tenantIdNumber > 0) {
			getTenant();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkId, tenantIdNumber]);

	if (isLoadingPage) {
		return <LoadingScreen />;
	}

	return (
		<Page className="bg-light p-3 ps-4" title={pageTitle} >
			<Button
				className="btn-ghost-primary ms-n3"
				icon={<IconArrowLeft />}
				onClick={() => navigate('/networks')}
			>
				{t('navigation.back')}
			</Button>
			<PageHero title={networkId !== '0' ? t('networks.edit_network') : t('networks.add_new_network')} />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-4">
						<Controller
							control={methods.control}
							name="name"
							rules={{
								required: t('this_field_is_required')
							}}
							render={({ field, fieldState }) =>
								<FormGroup label={t('networks.network_name')} field={field} fieldState={fieldState}>
									<Input
										error={!!fieldState.error?.message}
										{...field}
										placeholder={t('networks.enter_network_name')}
									/>
								</FormGroup>
							}
						/>
						<Controller
							control={methods.control}
							name="networkStateCode"
							rules={{
								required: t('this_field_is_required'),
							}}
							render={({ field, fieldState }) =>
								<FormGroup label={t('schools.school_state')} fieldState={fieldState} className="pb-2" required={true}>
									<PickListSelect
										picklistTypeCode="State"
										isMulti={false}
										value={field.value}
										onChange={field.onChange}
									/>
								</FormGroup>
							}
						/>
						<Controller
							control={methods.control}
							name="description"
							rules={{
								required: t('this_field_is_required'),
							}}
							render={({ field, fieldState }) =>
								<FormGroup label={t('schools.description')} fieldState={fieldState} className="pb-2" required={true}>
									<TextArea
										error={!!fieldState.error?.message}
										{...field}
										placeholder={t('schools.school_description')}
									/>
								</FormGroup>
							}
						/>
						<Controller
							control={methods.control}
							name="isActive"
							render={({ field, fieldState }) =>
								<FormGroup label={t('users.status')} field={field} fieldState={fieldState}>
									<RadioGroup<boolean>
										value={field.value ?? true}
										onChange={field.onChange}
										options={[
											{
												label: t('active'),
												value: true
											},
											{
												label: t('inactive'),
												value: false
											}
										]}
									/>
								</FormGroup>
							}
						/>
						<ResourceNaming networkId={tenantIdNumber} />
					</div>
				</div>
				<Button
					className="btn-primary me-2"
					onClick={methods.handleSubmit(submitTenant)}
					loading={isLoading}
					disabled={isLoading}
				>
					{t('navigation.save')}
				</Button>
				<Button
					className="btn-outline-primary"
					onClick={onCancelClick}
				>
					{t('cancel')}
				</Button>

			</div>
		</Page>
	);


	function onCancelClick() {
		navigate('/networks');
	}

	function submitTenant(values: Tenant) {
		setIsLoading(true);
		TenantAPI.saveTenant(values)
			.then(() => {
				if (tenantIdNumber <= 0) {
					setIsLoading(false);
					// if on new Org page, redirect to Edit page, else redirect to list page
					toast.success(t('networks.successfully_added_network'));
				} else {
					toast.success(t('networks.network_updated'));
				}
				navigate('/networks');
			})
			.catch((_err: any) => {
				toast.error('Something went wrong!', {
					autoClose: 3000,
					theme: 'dark'
				});
				setIsLoading(false);
			});
	}
};

export default TenantDetailsPage;
