import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Page, FormGroup, LoadingPage, Input, RadioGroup, Button, ModalOverlay, Select } from '@punchcard/core';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import coreAPI from 'api/coreAPI';
import EntityHeader from '@punchcard/core/components/EntityHeader';


const UsersPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
	const [validatingEmail, setValidatingEmail] = React.useState<boolean>(false);
	const [showRestPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false);
	const [roleOptions, setRoleOptions] = React.useState<{ value: string, label: string }[]>([]);
	const userId: number = parseInt(params.userId ?? '0');
	const networkId: number = parseInt(params.networkId ?? '1');
	const methods = useForm<AdminSchoolUserDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			userTypeCode: networkId === 1 ? 'Student' : 'Teacher',
			roleId: 0,
			isActive: true,
		}
	});
	const { isDirty } = methods.formState;

	const { control, reset, getValues } = methods;
	const isSSO = methods.watch('isSsoUser');
	const pageTitle = userId === 0 ? t('adminUsers.add_admin_user') : t('adminUsers.edit_admin_user');
	const isActive = methods.watch('isActive');
	const userType = methods.watch('userTypeCode');
	React.useEffect(() => {
		async function fetchData() {
			setLoading(true);
			const school = await adminAPI.school.getTenantUser(userId);
			if (networkId !== 1 && userId === 0) { // if adding a user with a networkId other than 1, default to teacher
				const updatedSchool = { ...school, userTypeCode: school.userTypeCode = 'Teacher' } as AdminSchoolUserDTO;
				reset(updatedSchool);
			} else {
				reset(school);
			}
			const roles = await coreAPI.getRolesForCurrentUser(userId);
			if (roles && roles.length > 0) {
				const roleOptions = roles.map((role) => {
					return { value: role.id.toString(), label: role.name };
				});
				setRoleOptions(roleOptions);
			}

			setLoading(false);
		}
		fetchData();
	}, [reset, userId]);
	const handleResetPassword = async () => {
		try {
			setShowSpinner(true);
			const result = await adminAPI.users.postResetUserPassword(userId);
			if (result) {
				toast.success(t('users.an_email_has_been_sent', { email: getValues('email') }));
				setShowResetPasswordModal(false);
			} else {
				toast.error(t('users.failed_to_update_password'));
			}
		} catch (error) {
			toast.error(t('users.failed_to_update_password'));

		}
		setShowSpinner(false);
	};
	const getUserName = () => {
		const data = getValues();
		if (data.firstName && data.lastName) {
			return `${data.firstName} ${data.lastName}'s`;
		} else if (data.firstName) {
			return `${data.firstName}'s`;
		} else if (data.lastName) {
			return `${data.lastName}'s`;
		} else {
			return 'the';
		}
	};
	const submitData = async () => {
		setLoading(true);
		const data = getValues();
		const dto: UserDetailsDTO = {
			id: userId,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			userType: data.userTypeCode,
			isActive: data.isActive,
			studentNumber: '',
			dateOfBirth: undefined,
			tenantId: networkId,
			roleIds: userType === 'Student' ? [] : [data.roleId],
		};
		const user = await coreAPI.postTenantUser(dto);
		if (user) {
			toast.success(t('users.user_saved'));
			navigate(-1);
		} else {
			toast.error(t('users.unable_to_save_user'));
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<Page className="bg-light p-3 ps-5" title={pageTitle} >
			<FormProvider {...methods}>
				<div className="container-fluid">
					<div className="row">
						<div className=" col-lg-7">
							<EntityHeader title={pageTitle} parentRoute={networkId === 1 ? '/admin/users' : `/networks/${networkId}/educators`} isDirty={isDirty} />
							<div className=" bg-white p-3 rounded-2 mt-3">
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="firstName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.first_name')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="lastName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.last_name')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="email"
											rules={{
												required: t('this_field_is_required'),
												validate: async (value) => {
													setValidatingEmail(true);
													const result = await adminAPI.users.getIsValidEmail(userId, value);
													setValidatingEmail(false);
													return (result || t('users.duplicate_email'));
												},
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.email_address')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														disabled={isSSO}
														spinner={validatingEmail}
														{...field}
														onBlur={() => {
															methods.trigger('email'); // Trigger validation onChange
														}}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								{isSSO === false && isActive === true &&
									<div className="row">
										<div className="col">
											<div className="mb-3 pb-2">
												<p className="form-label">Reset password</p>
												<p>{t('users.click_reset_email_password')}</p>
												<Button className="btn-outline-secondary" onClick={() => setShowResetPasswordModal(true)}>{t('users.reset_password')}</Button>
											</div>
										</div>
									</div>
								}
								{isSSO &&
									<div className="row">
										<div className="col">
											<div className="mb-3 pb-2">
												<p className="form-label">{t('users.reset_password')}</p>
												<p>{t('users.this_user_is_sso_enforced')}</p>
											</div>
										</div>
									</div>
								}
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="userTypeCode"
											render={({ field }) =>
												<FormGroup label={t('users.user_type')} className="pb-2">
													<RadioGroup
														options={[
															{ label: 'Admin', value: 'Admin' },
															{ label: 'School Staff', value: 'Teacher' },
															{ label: 'Student', value: 'Student' }
														]}
														value={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								{userType !== 'Student' && <div className="row">
									<div className="col">
										<Controller
											control={control}
											name="roleId"
											render={({ field }) =>
												<FormGroup label={t('users.role_name')} className="pb-2">
													<Select
														{...field}
														value={field.value.toString()}
														options={roleOptions}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								}
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="isActive"
											render={({ field }) =>
												<FormGroup label={t('users.status')} className="pb-2" >
													<RadioGroup
														options={[
															{ label: 'Active', value: true },
															{ label: 'Inactive', value: false }
														]}
														value={field.value ?? true}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div>
									<Button onClick={methods.handleSubmit(submitData)} className="btn-primary">{userId === 0 ? t('adminUsers.send_invite') : t('navigation.save')}</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormProvider>
			<ModalOverlay
				isOpen={showRestPasswordModal}
				loading={showSpinner}
				modalSize="md"
				onRequestClose={() => setShowResetPasswordModal(false)}
				headerChildren={t('users.reset_password')}
				confirmButtonChildren={t('users.reset')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					handleResetPassword();
				}}
				cancelButtonAction={() => {
					setShowResetPasswordModal(false);
				}}
			>
				{t('users.are_you_sure_you_want_to_reset_password', { fullName: getUserName(), emailAddress: getValues('email') })}
			</ModalOverlay>
		</Page >
	);
};

export default UsersPage;


