import React from 'react';
import { Button, DataTable, Input, ModalOverlay } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import tenantAPI from 'api/tenantAPI';
import { IconEditUnfilled } from '@punchcard/core/icons';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface NetworkResourcesProps {
	networkId: number;
}

const CourseResources = (NetworkResourcesProps: NetworkResourcesProps) => {
	const { t } = useTranslation();
	const { networkId } = NetworkResourcesProps;
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const methods = useForm<{ documentTypes: TenantDocumentTypeDTO[] }>();
	const closeModal = () => {
		if (methods.formState.isDirty) {
			setShowConfirmationModal(true);
		} else {
			setShowModal(false);
		}
	};
	const submitCustomTypes = async () => {
		const data = methods.getValues();
		setShowModal(false);
		await tenantAPI.saveTenantDocumentTypes(networkId, data.documentTypes);
		getDocumentTypeList();
		toast.success(t('documentType.resource_names_saved'));
	};
	async function getDocumentTypeList() {
		setLoading(true);
		const result = await tenantAPI.getTenantDocumentTypes(networkId);
		methods.reset({
			documentTypes: result.map((item) => ({
				...item,
				documentTypeNameCustom: item.documentTypeNameCustom || item.documentTypeName
			}))
		});
		setLoading(false);
	}
	React.useEffect(() => {
		getDocumentTypeList();
	}, [networkId, methods, showModal]);
	const { fields } = useFieldArray({
		control: methods.control,
		name: 'documentTypes', // Root-level array
	});
	// const submitDocumentType = async (documentType: TenantDocumentTypeDTO[]) => {
	// 	await tenantAPI.saveTenantDocumentType(networkId, documentType);
	// };
	const columns = [
		{
			Header: 'documentTypeCode',
			accessor: 'documentTypeCode',
			show: false
		},
		{
			Header: t('documentType.document_type_header'),
			accessor: 'documentTypeName',
			width: '50%'
		},
		{
			accessor: 'documentTypeNameCustom',
			Header: t('documentType.document_type_shows_as'),
			width: '50%'
		}

	];

	return (
		<React.Fragment>
			<style>
				{`
				
				`}
			</style>
			<label className="form-label mb-0">{t('documentType.resource_naming')}</label>
			<div className="mt-0">{t('documentType.resource_description')}
				<Button className="btn-outline-primary border-0" onClick={() => { setShowModal(true) }}  ><IconEditUnfilled style={{ width: '25px' }} /></Button>
			</div>
			<DataTable
				data={fields}
				columns={columns}
				resizable={false}
				sortable={false}
				filterable={false}
				showPagination={false}
				noDataText={t('documentType.document_type_not_found')}
				loading={loading}
				className="z-0 noSort-data-table noHover-datatable"
			/>
			<ModalOverlay
				modalPosition="right"
				modalSize="lg"
				isOpen={showModal}
				onRequestClose={() => closeModal()}
				hideCloseButton={true}
				headerChildren={t('documentType.resource_naming')}
				footer={true}
				confirmButtonChildren={t('confirm')}
				cancelButtonChildren={t('documentType.discard')}
				confirmButtonAction={() => { submitCustomTypes(); }}
				cancelButtonAction={() => closeModal()}
			>
				<div>{t('documentType.rename_how_resources_appear')}</div>
				<div className="row mt-2">
					<div className="col-6 fw-bold">{t('documentType.document_type_header')}</div>
					<div className="col-6 fw-bold">
						{t('documentType.document_type_shows_as')}
					</div>
				</div>
				<div className="row border-bottom ">
					<div className="col-6 fw-bold"></div>
					<div className="col-6 fst-italic small">
						{t('documentType.maxiumum_characters')}
					</div>
				</div>
				{fields.map((field, index) => (

					<Controller
						key={`customfield${field.id}`}
						control={methods.control}
						name={`documentTypes.${index}.documentTypeNameCustom`}
						rules={{
							required: t('this_field_is_required'),
						}}
						render={({ field, fieldState }) =>
							<React.Fragment>
								<div className="row">
									<div className="col-6 mt-2">{fields[index].documentTypeName}</div>
									<div className="col-6 mt-2">
										<Input
											{...field}
											maxLength={24}
											error={!!fieldState.error?.message}
											placeholder={t('documentType.document_type_shows_as')}
											onChange={field.onChange}
										/>
									</div>
								</div>
							</React.Fragment>
						}
					/>
				))
				}
			</ModalOverlay>
			<ModalOverlay
				isOpen={showConfirmationModal}
				modalSize="md"
				onRequestClose={() => setShowConfirmationModal(false)}
				headerChildren={t('discard_changes')}
				confirmButtonChildren={t('discard')}
				cancelButtonChildren={t('navigation.back')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					setShowConfirmationModal(false);
					setShowModal(false);
				}}
				cancelButtonAction={() => {
					setShowConfirmationModal(false);
				}}
			>
				{t('documentType.are_you_sure_discard')}
				<br />{t('documentType.are_you_sure_discard2')}
			</ModalOverlay>
		</React.Fragment>
	);
};
export default CourseResources;
