import React from 'react';
import { Button, DataTable, LoadingPage, Page, PageHero, ActionIcon, setStatusCellValue, filterByStatus, StatusFilterMethod } from '@punchcard/core';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconArrowLeft, IconEdit } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';


const EducatorList = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [schoolEducatorList, setSchoolEducatorList] = React.useState<AdminTenantEducatorDTO[]>([]);
	const [networkName, setNetworkName] = React.useState<string>('');
	const [loading, setLoading] = React.useState<boolean>(true);
	const params = useParams();
	const networkId: number = Number(params.networkId);
	React.useEffect(() => {
		async function getEducatorList() {
			const educators = await adminAPI.school.getTenantEducatorList(networkId);
			if (educators !== null) {
				setSchoolEducatorList(educators.educatorList);
				setNetworkName(educators.tenantName);
			} else {
				toast.error(t('unable_to_retrieve_school_educator_list'));
			}
			setLoading(false);
		}
		getEducatorList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openAdminlUser = (row: RowInfo) => {
		navigate(`/admin/users/${row.original.id}/${networkId}`);
	};

	const newUser = async () => {
		navigate(`/admin/users/0/${networkId}`);
	};

	const columns = [
		{
			Header: 'User ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('users.full_name'),
			accessor: 'fullName'
		},
		{
			Header: t('adminUsers.email_address'),
			accessor: 'email',
		},
		{
			Header: t('schools.school_list'),
			accessor: 'schoolList',
			Cell: (rowInfo: RowInfo) =>
				rowInfo.original.schoolList.join(', ')
			,
		},
		{
			Header: t('sites.sites'),
			accessor: 'siteList',
			Cell: (rowInfo: RowInfo) =>
				rowInfo.original.siteList.join(', ')
			,
		},
		{
			Header: t('users.role_name'),
			accessor: 'roleName',
		},
		{
			Header: t('adminUsers.status'),
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<div className="d-flex w-100">
					<ActionIcon title="Edit" icon={IconEdit} onClick={() => { openAdminlUser(rowInfo); }} />
				</div>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},

	];

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Page className="bg-light px-4" title={t('schools.school_user_list')}>
			{networkId !== undefined &&
				<React.Fragment>
					<Button
						className="btn-ghost-primary ms-n3"
						icon={<IconArrowLeft />}
						onClick={() => navigate('/networks')}
					>
						{t('navigation.back')}
					</Button>
					<h6 className={'ms-3'}>{networkName}</h6>
				</React.Fragment>
			}
			<PageHero title={t('networks.network_educators')}>
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-between">
						<Button onClick={newUser} className="btn-primary">
							<IconAdd />
							{t('users.add_user')}
						</Button>
					</div>
				</div>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolEducatorList}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('users.no_users_found')}
					loading={loading}
				/>
			</div>
		</Page>
	);

};

export default EducatorList;