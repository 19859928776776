import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: 'en-US',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			'en-US': {
				translation: {
					welcome_lyla: 'Welcome to LYLA Admin',
					logout: 'Log out',
					loading: 'Loading...',
					close: 'Close',
					cancel: 'Cancel',
					confirm: 'Confirm',
					action: 'Action',
					edit: 'Edit',
					add: 'Add',
					remove: 'Remove',
					active: 'Active',
					inactive: 'Inactive',
					select_all: 'Select all',
					this_field_is_required: 'This field is required',
					discard: 'Discard',
					discard_changes: 'Discard changes?',
					discard_changes_message: 'You have unsaved changes. Are you sure you would like to discard them?',
					navbar: {
						courses: 'Courses',
						schools: 'Schools',
						pick_lists: 'Pick Lists',
						admin_users: 'Admin Users',
						admin_tools: 'Admin Tools',
						networks: 'Networks',
					},
					courses: {
						course_list: 'Courses',
						no_courses_found: 'No courses found',
						no_schools_selected_for_course: 'No schools selected for {{courseName}}',
						course: 'Course',
						course_details: 'Course Details',
						course_name: 'Course Name',
						course_information: 'Course Information',
						code: 'Code',
						grade: 'Grade',
						course_documents: 'Course Documents',
						course_files: 'Course Files',
						unit_name: 'Unit Name',
						unit_number: 'Unit Number',
						unit_documents: 'Unit Documents',
						unit: 'Unit',
						unit_pdf: 'Unit PDF',
						lesson_name: 'Lesson Name',
						lesson: 'Lesson',
						page_numbers: 'Page Numbers',
						activity_name: 'Activity name',
						activity: 'Activity',
						activity_type: 'Type',
						course_tags: 'Tags',
						pdf_page_range: 'PDF Page Range',
						use_commas_to_split: 'Use commas to split page ranges (eg. 1-5,6-10)',
						remove: 'Remove',
						grade_value: 'Grade Value',
						include_grade: 'Include Grade',
						add_lesson: 'Add Lesson',
						add_activity: 'Add Activity',
						add_unit: 'Add Unit',
						course_saved: 'Course Saved',
						unable_to_save_course: 'Unable to save course',
						are_you_sure_you_want_to_delete_this_course: 'Are you sure you want to delete this Course?',
						are_you_sure_you_want_to_delete_this_unit: 'Are you sure you want to delete this Unit?',
						are_you_sure_you_want_to_delete_this_lesson: 'Are you sure you want to delete this Lesson?',
						are_you_sure_you_want_to_delete_this_activity: 'Are you sure you want to delete this Activity?',
						are_you_sure_you_want_to_delete_this_assessment: 'Are you sure you want to delete this Assessment?',
						course_subject: 'Subject',
						course_grades: 'Grades',
						course_levels: 'Levels',
						course_lexiles: 'Lexile Levels',
						course_states: 'States',
						unit_part_number: 'Part Number',
						course_modalities: 'Modalities',
						additional_materials: 'Additional Materials',
						additional_tech: 'Additional Tech',
						permission_slip_required: 'Permission Slip Required',
						course_deleted: 'Course Deleted',
						unable_to_delete_course: 'Unable to delete course',
						add_assessment: 'Add Assessment',
						assessment: 'Assessment',
						assessment_name: 'Assessment Name',
						select_schools: 'Select schools',
						allow_access: 'Allow access',
						successfully_removed_school: 'Successfully revoked access to the school.',
						successfully_assigned_schools: 'Successfully gave access to {{numberOfSchools}} school(s).',
						unable_to_give_access_to_school: 'Unable to give access to the school(s).',
						unable_to_revoke_access_to_school: 'Unable to revoke access to the school.',
						revoke_access: 'Revoke access',
						revoke_access_message: 'Are you sure you want to revoke access to {{courseName}} for {{schoolName}}?',
						schools_with_access: 'Schools with access',
						all_schools_count: 'All ({{count}})',
						select_all_schools: 'Select all ({{count}} schools)',
						select_at_least_one_school: 'Please select at least one school.',
						invalid_file_format: 'File type not supported. File upload only accepts PDF files.',
					},
					navigation: {
						back: 'Back',
						save: 'Save',
						delete: 'Delete',
						save_and_exit: 'Save and Exit',
						create_new: 'Create New',
					},
					documents: {
						upload_pdf: 'Upload PDF',
						no_documents_found: 'No documents found',
						are_you_sure_you_want_to_delete_this_document: 'Are you sure you want to delete this document?',
					},
					pagination: {
						page: 'Page',
						of: 'of',
					},
					schools: {
						school_list: 'Schools',
						school_name: 'School Name',
						school_city: 'City',
						school_state: 'State',
						add_school: 'Add School',
						add_schools: 'Add Schools',
						edit_school: 'Edit School',
						school_saved: 'School Saved',
						school_timezone: 'Time zone',
						school_status: 'Status',
						are_you_sure_you_want_to_delete_this_school: 'Are you sure you want to delete this School?',
						school_user_list: 'Users',
						no_schools_found: 'No schools found',
						school_deleted: 'School Deleted',
						unable_to_delete_school: 'Unable to delete school',
						unable_to_retrieve_school_list: 'Unable to retrieve school list',
						students_school: 'Student\'s School',
						network_name: 'Network name',
						school_description: 'Enter school description',
						description: 'Description',
						network_warning: 'Note: Once a network is selected, it cannot be changed or undone.',
					},
					users: {
						add_user: 'Add User',
						full_name: 'Full Name',
						first_name: 'First Name',
						name: 'Name',
						last_name: 'Last Name',
						email_address: 'Email Address',
						user_type: 'User Type',
						user_status: 'User Status',
						user_details: 'User',
						edit_user: 'Edit User',
						status: 'Status',
						email: 'Email',
						no_users_found: 'No users found',
						user_saved: 'User Saved',
						unable_to_save_user: 'Unable to save user',
						are_you_sure_you_want_to_delete_this_user: 'Are you sure you want to delete this User?',
						are_you_sure_you_want_to_reset_password: 'Are you sure you want to reset {{fullName}} password for {{emailAddress}}?',
						reset: 'Reset',
						reset_password: 'Reset password',
						an_email_has_been_sent: 'An email has been sent to {{email}}',
						duplicate_email: 'This email is already in use',
						role_name: 'Role',
						student_number: 'SSID',
						date_of_birth: 'Date of Birth',
						failed_to_update_password: "Failed to update the user's password",
						click_reset_email_password: `Clicking "Reset password" will reset the user's password and they will receive an email with the new password.`,
						this_user_is_sso_enforced: `This user's account is SSO-enforced.  They must reset their password themselves using their SSO provider's password reset process.`,
					},
					transfer: {
						transferring_will_cause_chaos: 'Transferring a student to a new tenant may result in the loss of access to records and courses.  Please ensure that this action is final and intended',
						transfer_student: 'Transfer Student',
						transfer_student_warning: 'Warning:  Once a transfer is complete, the new school will not have access to the student\'s historical work, and you will lose the ability to modify the student\'s information.',
						transfer_student_warning_next: 'This action is permanent and cannot be undone.  Please confirm all details before proceeding.',
						transfer_details: 'Transfer details:',
						transfer_new_school: 'New school',
						student: 'Student:',
						current_school: 'Current school:',
						confirm_transfer_understand: 'I understand and confirm the student transfer',
						confirm_transfer: 'Confirm transfer',
						transfer_history: 'Transfer History',
						transfer_date: 'Transfer Date',
						previous_school: 'Previous School',
						new_school: 'New School',
					},
					adminUsers: {
						admin_users: 'Admin Users',
						add_admin_user: 'Add Admin User',
						name: 'NAME',
						email_address: 'EMAIL ADDRESS',
						user_type: 'USER TYPE',
						status: 'STATUS',
						edit_admin_user: 'Edit admin user',
						send_invite: 'Send Invite',
					},
					error: {
						message404: 'Page not found',
						description404: 'Oops! The page you\'re looking for can\'t be found.',
						message403: 'Access Denied',
						description403: 'We\'re sorry, but it looks like you don\'t have permission to view this page.',
						back_to_home_page: 'Back to home page',
					},
					sites: {
						sites: 'Sites',
						users: 'Users',
						add_site: 'Add Site',
						name: 'Name',
						description: 'Description',
						edit_site: 'Edit Site',
						delete: 'Delete',
						delete_site: 'Delete Site',
						want_delete_site: 'Are you sure you want to delete {{siteName}}?',
						no_site_on_school: 'No sites found on {{schoolName}}',
						no_user_on_site: 'No users found on {{siteName}}',
						userType: 'User Type',
						unable_to_retrieve_site_users: 'Unable to retrieve site users',
						unable_to_retrieve_school_sites: 'Unable to retrieve school sites',
						unable_to_retrieve_users: 'Unable to retrieve users',
						successfully_deleted_site: 'Successfully deleted site.',
						successfully_added_site: 'Successfully added site.',
						successfully_updated_site: 'Successfully updated site.',
						unable_to_delete_site: 'Unable to delete site.',
						unable_to_add_site: 'Unable to add site.',
						unable_to_update_site: 'Unable to update site.',
						add_user: 'Add user',
						select_users: 'Select users',
						successfully_added_users: 'Successfully added user(s).',
						unable_to_add_users: 'Unable to add user(s).',
						remove_user: 'Remove user',
						successfully_removed_user: 'Successfully removed user.',
						unable_to_remove_user: 'Unable to remove user.',
						remove_user_message: 'Are you sure you want to remove {{userName}} from {{siteName}}?',
						select_at_least_one_user: 'Please select at least one user.',
					},
					termsAndConditions: {
						terms_of_service: 'Terms of Service',
						agree_to_terms: 'By clicking "Continue" you agree to the ',
						terms_of_service_link: 'Terms of Service',
						continue: 'Continue',
					},
					networks: {
						add_network: 'Add Network',
						add_new_network: 'Add New Network',
						edit_network: 'Edit Network',
						network_name: 'Network Name',
						enter_network_name: 'Enter a network name',
						successfully_added_network: 'Successfully added network.',
						network_updated: 'Network updated',
						network_educators: 'Educators',
					},
					roles: {
						role_updated: 'Role Updated',
						role_hierarchy: 'Role Hierarchy',
						role_hierarchy_description: 'Use the up and down arrows to modifiy the hierarchy level of the role you are currently creating or editing',
						role_hierarchy_up: 'Move up',
						role_hierarchy_down: 'Move down',
						role_name: 'Name',
						no_roles_found: 'No roles found',
						roles: 'Roles',
						role_details: 'Role Details',
						add_role: 'Add Role',
						edit_role: 'Edit Role',
					},
					documentType: {
						document_type_header: 'Resource Type',
						resource_naming: 'Resource Naming',
						resource_description: 'Resource types show these names to this network',
						resource_appear: 'Rename how resources appear within a course in this network',
						document_type_shows_as: 'Shows As',
						document_type_not_found: 'Document Types not found',
						AnswerKey: 'Answer Key',
						InstructorManual: 'Instructor Manual',
						Reader: 'Reader',
						StudyGuide: 'Study Guide',
						are_you_sure_discard: 'Are you sure you want to discard changes?',
						are_you_sure_discard2: 'Any unsaved changes will be lost.',
						rename_how_resources_appear: 'Rename how resources appear within a course in this network',
						maxiumum_characters: '(Maximum 24 characters)',
						resource_names_saved: 'Resource names saved',
						discard: 'Discard',
					}
				}
			},
		}
	}
	);

export default i18n;